import axios from 'axios'

export default {
  getArticleTypesSummaryStatistics(lfdnr, fromMonth, toMonth) {
    return axios.get(`/v1/statistics/distributors/${lfdnr}/statistic`, {
      params: { from: fromMonth, to: toMonth }
    })
  },
  getArticleTypesStatistics(lfdnr, fromMonth, toMonth) {
    return axios.get(`/v1/statistics/distributors/${lfdnr}/articletypes`, {
      params: { from: fromMonth, to: toMonth }
    })
  },
  getArticleCategoriesStatistics(lfdnr, articleType, fromMonth, toMonth) {
    return axios.get(
      `/v1/statistics/distributors/${lfdnr}/articletypes/${articleType}/categories`,
      {
        params: { from: fromMonth, to: toMonth }
      }
    )
  },
  getArticlesOfCategoryStatistics(
    lfdnr,
    articleType,
    category,
    fromMonth,
    toMonth
  ) {
    return axios.get(
      `v1/statistics/distributors/${lfdnr}/articletypes/${articleType}/categories/${category}/articles`,
      {
        params: { from: fromMonth, to: toMonth }
      }
    )
  },
  getArticleDetailed(lfdnr, artNr, fromMonth, toMonth) {
    return axios.get(`/v1/statistics/distributors/${lfdnr}/articles/${artNr}`, {
      params: { from: fromMonth, to: toMonth }
    })
  },
  getArticleDetailedFeatures(lfdnr, artNr, fromMonth, toMonth) {
    return axios.get(
      `/v1/statistics/distributors/${lfdnr}/articles/${artNr}/features`,
      {
        params: { from: fromMonth, to: toMonth }
      }
    )
  },
  // ArticleSearch
  async getSearchResults(lfdnr, params, controller) {
    const response = await axios.get(
      `/v1/statistics/distributors/${lfdnr}/articles/search`,
      {
        params,
        signal: controller.signal
      }
    )

    if (response.status != 200) {
      throw axios.AxiosError.ERR_BAD_REQUEST
    } else {
      return response.data
    }
  }
}
